<template lang="pug">
.loading-wrapper
  slot
  b-loading(:active="isLoading" :is-full-page="false" :can-cancel="false")
</template>

<script>
export default {
  data() {
    return {
      isLoading: false
    }
  },
  mounted() {
    this.$on('update:start', () => {
      this.isLoading = true
    })
    this.$on('update:done', () => {
      this.isLoading = false
    })
  },
  beforeDestroy() {
    this.$off('update:start')
    this.$off('update:done')
  }
}
</script>

<style scoped lang="sass">
.loading-wrapper
  position: relative
  overflow: hidden
</style>
