<script>
import wrap from 'word-wrap'
import { groupBy, compact, find, assign, sortBy } from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import dayjs from '@/dayjs'
import { Line } from '@/vue-chartjs'
import { formatDate } from '@/misc/filters'
import currencyFormatter from '@/misc/currencyFormatter'
import { exportPNG } from '@/components/charts/helpers'

const datasetConfig = {
  networth: {
    label: 'Net Worth',
    backgroundColor: '#43ae43'
  },
  assets: {
    label: 'Net Assets',
    backgroundColor: '#43ae43'
  },
  liabilities: {
    label: 'Net Liabilities',
    backgroundColor: '#ffdd57'
  }
}

export default {
  extends: Line,
  props: {
    // One of: networth, assets, liabilities, category:id
    mode: String,
    startDate: Date,
    endDate: Date,
    backgroundColor: String,
    // One of: day, month, year
    groupBy: String
  },
  data() {
    return {
      isUpdating: true,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              time: {
                displayFormats: {
                  millisecond: 'MMM D',
                  second: 'MMM D',
                  minute: 'MMM D',
                  hour: 'MMM D'
                }
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                callback: value =>
                  currencyFormatter(value, this.activeCurrencyWithSubunit)
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            title: ([item], { datasets }) => {
              const dataset = datasets[item.datasetIndex]
              const point = dataset.data[item.index]
              let lines = [formatDate(point.t, this.groupBy)]
              if (point.note.length) {
                const wrapped = wrap(point.note, { indent: '' })
                lines = lines.concat(wrapped.split(/\n/))
                if (lines.length > 5) {
                  lines = lines.slice(0, 5)
                  lines[4] += '…'
                }
              }
              return lines
            },
            label: item =>
              currencyFormatter(item.yLabel, this.activeCurrencyWithSubunit)
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('currencies', [
      'activeCurrencyWithSubunit',
      'currencyFormatOptions'
    ]),
    ...mapState('entries', ['entries'])
  },
  asyncComputed: {
    async chartdata() {
      this.isUpdating = true
      this.missingRateData = false
      let config
      let categoryId
      if (this.mode.match(/^category:/)) {
        categoryId = this.mode.split(':', 2)[1]
        const category = this.$store.getters['categories/getCategoryById'](
          categoryId
        )
        config = {
          label: category.name,
          backgroundColor: category.isAsset
            ? datasetConfig.assets.backgroundColor
            : datasetConfig.liabilities.backgroundColor
        }
        if (this.backgroundColor) config.backgroundColor = this.backgroundColor
      } else {
        config = assign({}, datasetConfig[this.mode])
        if (this.backgroundColor) config.backgroundColor = this.backgroundColor
      }
      const currency = this.activeCurrencyWithSubunit
      const group = this.groupBy
      const { mode } = this
      const promises = this.entriesInDateRange().map(async entry => {
        let rate
        let value
        let point
        try {
          rate = await this.getExchangeRateOn({
            currency,
            date: entry.date
          })
        } catch {
          return undefined
        }
        switch (mode) {
          case 'networth':
            value = entry.netWorth
            break
          case 'assets':
            value = entry.netAssets
            break
          case 'liabilities':
            value = entry.netLiabilities
            break
          default:
            point =
              find(entry.assets, asset => asset.categoryId === categoryId) ||
              find(
                entry.liabilities,
                liability => liability.categoryId === categoryId
              )
            if (point) {
              value = point.amount / point.baseRate
            } else {
              value = 0
            }
        }
        return {
          t: entry.date,
          y: value * rate,
          note: entry.note || ''
        }
      })
      const data = await Promise.all(promises)
      const filteredData = sortBy(compact(data), ({ t }) => t)
      if (data.length !== filteredData.length) {
        this.missingRateData = true
      }
      // Update the granularity on the x axis settings
      this.options.scales.xAxes[0].time.unit = group
      this.isUpdating = false
      return {
        datasets: [
          {
            ...config,
            data: this.groupData(filteredData, group)
          }
        ]
      }
    }
  },
  watch: {
    chartdata() {
      if (this.chartdata) {
        this.cachedChartData = JSON.parse(JSON.stringify(this.chartdata))
        this.renderChart(this.chartdata, this.options)
      }
    },
    isUpdating: {
      immediate: true,
      handler(val) {
        if (val) this.$parent.$emit('update:start')
        else this.$parent.$emit('update:done')
      }
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn']),
    entriesInDateRange() {
      const entries = []
      this.entries.forEach(entry => {
        if (this.startDate && dayjs(entry.date).isBefore(this.startDate)) return
        if (this.endDate && dayjs(entry.date).isAfter(this.endDate)) return
        entries.push(entry)
      })
      return entries
    },
    groupData(data) {
      if (this.groupBy === 'day') return data
      let groups
      if (this.groupBy === 'month') {
        groups = groupBy(data, ({ t }) => dayjs(t).format('YYYY-MM'))
      } else {
        groups = groupBy(data, ({ t }) => dayjs(t).format('YYYY'))
      }
      // TODO - perhaps push an extra value onto the end for the last point?
      return Object.values(groups).map(group => ({
        t: dayjs(group[0].t)
          .startOf(this.groupBy)
          .toDate(),
        y: group[0].y,
        note: group.map(({ note }) => note).join('\n')
      }))
    },
    exportPNG(w, h) {
      exportPNG(w, h, this.$refs.canvas, this.$data._chart)
    },
    embedData() {
      if (!this.cachedChartData) return undefined
      if (!this.activeCurrencyWithSubunit) return undefined
      const datasets = this.cachedChartData.datasets.map(ds => ({
        label: ds.label,
        color: ds.backgroundColor
      }))
      const data = {}
      this.cachedChartData.datasets.forEach((ds, idx) => {
        ds.data.forEach(({ t, y }) => {
          if (!data[t]) data[t] = []
          data[t][idx] = y
        })
      })
      return {
        datasets,
        data: Object.entries(data).map(([t, ys]) => [t].concat(ys)),
        currency: this.activeCurrencyWithSubunit,
        currencyFormat: this.currencyFormatOptions(
          this.activeCurrencyWithSubunit
        ),
        granularity: this.groupBy
      }
    }
  }
}
</script>
