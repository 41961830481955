<script>
import { round } from 'lodash'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { mapState, mapGetters, mapActions } from 'vuex'
import { Doughnut } from '@/vue-chartjs'

import currencyFormatter from '@/misc/currencyFormatter'
import { getTypeColor, getTypeName } from '@/misc/type_map'
import { exportPNG } from '@/components/charts/helpers'

export default {
  extends: Doughnut,
  props: {
    date: Date,
    // One of: assets, liabilities
    mode: String,
    disable: Object
  },
  data() {
    return {
      isUpdating: true,
      version: 0,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (item, { labels, datasets }) => {
              const label = labels[item.index]
              const value = datasets[item.datasetIndex].data[item.index]
              const amount = currencyFormatter(
                value,
                this.activeCurrencyWithSubunit
              )
              const percentAmount =
                (100 * value) / this.chartdata.extraInfo.total
              return `${label}: ${amount} (${round(percentAmount, 1)}%)`
            }
          }
        },
        plugins: {
          datalabels: {
            backgroundColor: 'rgba(255,255,255,.6)',
            borderColor: 'white',
            borderRadius: 5,
            borderWidth: 0,
            color: 'rgba(0,0,0,.8)',
            font: {
              weight: 'bold'
            },
            padding: 6,
            display: ({ dataIndex, dataset }) => {
              const value = dataset.data[dataIndex]
              let percentAmount
              if (this.$data._chart) {
                const meta = this.$data._chart.getDatasetMeta(0)
                const shownTotal = this.chartdata.datasets[0].data
                  .map((x, idx) => {
                    if (meta.data[idx] && meta.data[idx].hidden) {
                      return 0
                    }
                    return x
                  })
                  .reduce((x, y) => x + y)
                percentAmount = (100 * value) / shownTotal
              } else {
                percentAmount = (100 * value) / this.chartdata.extraInfo.total
              }
              return percentAmount > 5
            },
            formatter: value => {
              const percentAmount =
                (100 * value) / this.chartdata.extraInfo.total
              return `${round(percentAmount, 1)}%`
            }
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters('currencies', [
      'activeCurrencyWithSubunit',
      'currencyFormatOptions'
    ]),
    ...mapState('entries', ['entries']),
    entry() {
      return this.$store.getters['entries/lastEntry'](this.date)
    }
  },
  asyncComputed: {
    async chartdata() {
      this.isUpdating = true
      this.missingRateData = false
      const { mode } = this
      const currency = this.activeCurrencyWithSubunit
      const { entry } = this
      const { date } = this
      const disabled = this.disable || {}
      const dataMap = new Map()
      const version = this.version // eslint-disable-line
      if (!entry || !date) return undefined
      // Iterate assets or liabilities, summing baseValue by category into dataMap
      entry[mode].forEach(({ categoryId, amount, baseRate }) => {
        // Skip disabled series
        if (disabled[categoryId]) return
        const category = this.$store.getters['categories/getCategoryById'](
          categoryId
        )
        const baseAmount = amount / baseRate
        let categoryType = category.type
        if (categoryType === 'custom' && category.customName) {
          categoryType = category.customName
        }
        dataMap.set(categoryType, (dataMap.get(categoryType) || 0) + baseAmount)
      })
      // Convert map to display currency and fill in labels
      const rate = await this.getExchangeRateOn({
        date: entry.date,
        currency
      }).catch(() => undefined)
      if (!rate) {
        this.missingRateData = true
        return undefined
      }
      const labels = []
      const data = []
      const colors = []
      let total = 0
      dataMap.forEach((baseValue, typeId) => {
        labels.push(getTypeName(typeId))
        colors.push(getTypeColor(typeId))
        const amount = rate * baseValue
        total += amount
        data.push(amount)
      })
      this.isUpdating = false
      return {
        labels,
        datasets: [{ data, backgroundColor: colors }],
        extraInfo: {
          total
        }
      }
    }
  },
  watch: {
    chartdata() {
      if (this.chartdata) {
        this.cachedChartData = JSON.parse(JSON.stringify(this.chartdata))
        this.renderChart(this.chartdata, this.options)
      }
    },
    isUpdating: {
      immediate: true,
      handler(val) {
        if (val) this.$parent.$emit('update:start')
        else this.$parent.$emit('update:done')
      }
    }
  },
  mounted() {
    this.addPlugin(ChartDataLabels)
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn']),
    exportPNG(w, h) {
      exportPNG(w, h, this.$refs.canvas, this.$data._chart)
    },
    embedData() {
      if (!this.cachedChartData) return undefined
      return {
        labels: this.cachedChartData.labels,
        colors: this.cachedChartData.datasets[0].backgroundColor,
        values: this.cachedChartData.datasets[0].data,
        currency: this.activeCurrencyWithSubunit,
        currencyFormat: this.currencyFormatOptions(
          this.activeCurrencyWithSubunit
        )
      }
    },
    recompute() {
      this.version += 1
    }
  }
}
</script>
