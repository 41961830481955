<script>
import { groupBy, compact, sortBy } from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import dayjs from '@/dayjs'
import { Bar } from '@/vue-chartjs'
import { formatDate } from '@/misc/filters'
import currencyFormatter from '@/misc/currencyFormatter'
import { getTypeColor, getTypeName } from '@/misc/type_map'
import { exportPNG } from '@/components/charts/helpers'

export default {
  extends: Bar,
  props: {
    startDate: Date,
    endDate: Date,
    // One of: day, month, year
    groupBy: String,
    disable: Object
  },
  data() {
    return {
      isUpdating: true,
      version: 0,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              stacked: true,
              offset: true,
              time: {
                displayFormats: {
                  millisecond: 'MMM D',
                  second: 'MMM D',
                  minute: 'MMM D',
                  hour: 'MMM D'
                }
              },
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                callback: value =>
                  currencyFormatter(value, this.activeCurrencyWithSubunit)
              },
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ]
        },
        tooltips: {
          mode: 'x',
          callbacks: {
            title: ([item], { datasets }) => {
              const dataset = datasets[item.datasetIndex]
              const point = dataset.data[item.index]
              return formatDate(point.t, this.groupBy)
            },
            label: (item, { datasets }) => {
              if (
                typeof item.yLabel === 'number' &&
                !Number.isNaN(item.yLabel)
              ) {
                const dataset = datasets[item.datasetIndex]
                const amount = currencyFormatter(
                  item.yLabel,
                  this.activeCurrencyWithSubunit
                )
                return `${dataset.label}: ${amount}`
              }
              return undefined
            },
            footer: (items, { datasets }) => {
              if (items.length < 2) {
                return ''
              }
              let total = 0
              items.forEach(({ datasetIndex, index }) => {
                const dataset = datasets[datasetIndex]
                const point = dataset.data[index]
                if (typeof point.y === 'number' && !Number.isNaN(point.y)) {
                  total += point.y
                }
              })
              const amount = currencyFormatter(
                total,
                this.activeCurrencyWithSubunit
              )
              return `Total: ${amount}`
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('currencies', [
      'activeCurrencyWithSubunit',
      'currencyFormatOptions'
    ]),
    ...mapState('entries', ['entries'])
  },
  asyncComputed: {
    async chartdata() {
      this.isUpdating = true
      this.missingRateData = false
      const datasetMap = new Map()
      const promises = []
      const currency = this.activeCurrencyWithSubunit
      const group = this.groupBy
      const entries = this.entriesInDateRange()
      const disabled = this.disable || {}
      const version = this.version // eslint-disable-line
      const dates = new Set()
      // Build a dataset for each asset/liability grouped by type
      entries.forEach(entry => {
        const getData = item => {
          // Skip disabled series
          if (disabled[item.categoryId]) return
          const category = this.$store.getters['categories/getCategoryById'](
            item.categoryId
          )
          let categoryType = category.type
          if (categoryType === 'custom' && category.customName) {
            categoryType = category.customName
          }
          if (!datasetMap.has(categoryType)) {
            datasetMap.set(categoryType, new Map())
          }
          const dataset = datasetMap.get(categoryType)
          let baseAmount = item.amount / item.baseRate
          // Convert liabilities to a negative value
          if (!category.isAsset) {
            baseAmount = -baseAmount
          }
          // Store total in base currency for that date
          dataset.set(entry.date, (dataset.get(entry.date) || 0) + baseAmount)
        }
        dates.add(entry.date)
        entry.assets.forEach(getData)
        entry.liabilities.forEach(getData)
      })

      // Convert each dataset to the display currency then group it
      // (important to convert first or there will be error due to currency fluctuations)
      datasetMap.forEach((dataMap, typeId) => {
        const categoryName = getTypeName(typeId)
        const dataPromises = []
        dates.forEach(date => {
          if (dataMap.has(date)) {
            const baseValue = dataMap.get(date)
            dataPromises.push(
              this.getExchangeRateOn({ date, currency })
                .then(rate => ({
                  t: date,
                  y: rate * baseValue
                }))
                .catch(() => undefined)
            )
          } else {
            dataPromises.push(
              new Promise(resolve => {
                resolve({ t: date, y: undefined })
              })
            )
          }
        })
        promises.push(
          Promise.all(dataPromises).then(data => {
            const filteredData = sortBy(compact(data), ({ t }) => t)
            if (filteredData.length !== data.length) {
              this.missingRateData = true
            }
            return {
              label: categoryName,
              backgroundColor: getTypeColor(typeId),
              data: this.groupData(filteredData, group)
            }
          })
        )
      })
      const datasets = await Promise.all(promises)
      // Update the granularity on the x axis settings
      this.options.scales.xAxes[0].time.unit = group
      this.isUpdating = false
      return { datasets }
    }
  },
  watch: {
    chartdata() {
      if (this.chartdata) {
        this.cachedChartData = JSON.parse(JSON.stringify(this.chartdata))
        this.renderChart(this.chartdata, this.options)
      }
    },
    isUpdating: {
      immediate: true,
      handler(val) {
        if (val) this.$parent.$emit('update:start')
        else this.$parent.$emit('update:done')
      }
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn']),
    entriesInDateRange() {
      const entries = []
      this.entries.forEach(entry => {
        if (this.startDate && dayjs(entry.date).isBefore(this.startDate)) return
        if (this.endDate && dayjs(entry.date).isAfter(this.endDate)) return
        entries.push(entry)
      })
      return entries
    },
    groupData(data) {
      if (this.groupBy === 'day') return data
      let groups
      if (this.groupBy === 'month') {
        groups = groupBy(data, ({ t }) => dayjs(t).format('YYYY-MM'))
      } else {
        groups = groupBy(data, ({ t }) => dayjs(t).format('YYYY'))
      }
      // TODO - perhaps push an extra value onto the end for the last point?
      return Object.values(groups).map(group => ({
        t: dayjs(group[0].t)
          .startOf(this.groupBy)
          .toDate(),
        y: group[0].y
      }))
    },
    exportPNG(w, h) {
      exportPNG(w, h, this.$refs.canvas, this.$data._chart)
    },
    embedData() {
      if (!this.cachedChartData) return undefined
      if (!this.activeCurrencyWithSubunit) return undefined
      const datasets = this.cachedChartData.datasets.map(ds => ({
        label: ds.label,
        color: ds.backgroundColor
      }))
      const data = {}
      this.cachedChartData.datasets.forEach((ds, idx) => {
        ds.data.forEach(({ t, y }) => {
          if (!data[t]) data[t] = []
          data[t][idx] = y
        })
      })
      return {
        datasets,
        data: Object.entries(data).map(([t, ys]) => [t].concat(ys)),
        currency: this.activeCurrencyWithSubunit,
        currencyFormat: this.currencyFormatOptions(
          this.activeCurrencyWithSubunit
        ),
        granularity: this.groupBy
      }
    },
    recompute() {
      this.version += 1
    }
  }
}
</script>
