export function exportPNG(w, h, canvas, chart, name = 'Nutworth Chart') {
  const oldH = canvas.height
  canvas.parentNode.style.width = `${w}px`
  canvas.parentNode.style.height = `${h}px`
  setTimeout(() => {
    // Set the background to white
    const context = canvas.getContext('2d')
    const compositeOperation = context.globalCompositeOperation
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
    context.globalCompositeOperation = 'destination-over'
    context.fillStyle = '#ffffff'
    context.fillRect(0, 0, canvas.width, canvas.height)

    // Download the canvas as PNG
    const img = canvas.toDataURL('image/png')
    const link = document.createElement('A')
    link.download = `${name}.png`
    link.href = img
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    // Restore the canvas background
    context.globalCompositeOperation = compositeOperation
    context.clearRect(0, 0, canvas.width, canvas.height)
    context.putImageData(imageData, 0, 0)

    // Reset the canvas size
    canvas.parentNode.style.width = 'auto'
    canvas.parentNode.style.height = `${oldH}px`
    chart.resize()
  }, 50)
}

export default {}
