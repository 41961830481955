<template lang="pug">
.display-currency-select(:class="{'has-subunits': !!subunits}")
  currency-select.base-currency(:value="activeCurrency" @input="updateDisplayCurrency" :placeholder="placeholder" :prefix="prefix")
  multi-select.subunits(v-if="subunits" :options="subunits" :value="activeSubunit" @input="setActiveSubunit" :allow-empty="false" :custom-label="getSubunitLabel" deselect-label="Selected" placeholder="Display as")
    template(slot="singleLabel" slot-scope="{ option }") {{ option.name }}
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { sortBy } from 'lodash'
import CurrencySelect from '@/components/CurrencySelect'

export default {
  components: {
    CurrencySelect
  },
  props: {
    placeholder: {
      type: String,
      default: 'Convert to'
    },
    prefix: {
      type: String,
      default: 'Viewing as'
    }
  },
  computed: {
    ...mapState('currencies', ['activeCurrency']),
    ...mapGetters('currencies', ['activeSubunit']),
    subunits() {
      const { activeCurrency } = this
      if (activeCurrency.subunits) {
        return sortBy(Object.values(activeCurrency.subunits), 'order')
      }
      return null
    }
  },
  methods: {
    ...mapActions('currencies', ['setDisplayCurrency', 'setActiveSubunit']),
    updateDisplayCurrency(currency) {
      this.setDisplayCurrency(currency.id)
    },
    getSubunitLabel({ description }) {
      return description
    }
  }
}
</script>

<style lang="sass" scoped>
.display-currency-select
  display: flex
  flex-direction: row
.has-subunits
  .multiselect
    ::v-deep
      .multiselect__tags
        border-radius: 0
        border-left-width: 0
      .multiselect__content-wrapper
        width: 300px
  .base-currency
    ::v-deep .multiselect__tags
      border-radius: 5px 0 0 5px
      border-left-width: 1px
  .multiselect.subunits
    ::v-deep
      .multiselect__tags
        border-radius: 0 5px 5px 0
      .multiselect__content-wrapper
        right: 0
  ::v-deep .multiselect__single
    white-space: nowrap
</style>
